import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthGuard } from './core/helpers/auth-guard';
import { UserRoleEnum } from './core/models/enum/user-role-enum';
import { EmailArchivePageComponent } from './email-archive/pages/email-archive-page/email-archive-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginPageComponent } from './login/pages/login-page/login-page.component';
import { MainAdminComponent } from './main-admin/main-admin.component';
import { MainProfileComponent } from './main-profile/main-profile.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
    {
        path: 'login',
        component: LoginPageComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent
    },
    {
        path: '',
        component: MainAdminComponent,
        canActivate: [AuthGuard],
        data: {userRoleEnum: [UserRoleEnum.Admin]},
        children: [
            {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full'
              },
            {
                path: 'search',
                loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
            },           
            {
                path: 'administrator',
                loadChildren: () => import('./administrators/administrators.module').then(m => m.AdministratorsModule),
            },
            {
                path: 'profile-details',
                loadChildren: () => import('./profile-details/profile-details.module').then(m => m.ProfileDetailsModule),
            },
            {
                path: 'email-archive',
                component: EmailArchivePageComponent,
            },

        ]
    },
    {
        path: '',
        component: MainProfileComponent,
        canActivate: [AuthGuard],
        data: {userRoleEnum: [UserRoleEnum.Profile]},
        children: [
            {
                path: '',
                redirectTo: 'edit-my-profile-page',
                pathMatch: 'full'
              },
            {
                path: 'edit-my-profile-page',
                loadChildren: () => import('./edit-my-profile/edit-my-profile.module').then(m => m.EditMyProfileModule),
            },
        ]
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                redirectTo: 'create-profile',
                pathMatch: 'full'
              },
            {
                path: 'create-profile',
                loadChildren: () => import('./create-profile/create-profile.module').then(m => m.CreateProfileModule),
            },
        ]
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes) // { enableTracing: true }
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
