import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseSearchParameters } from '../models/base-search-parameters';
import { FocusResultWrapper } from '../models/focus-result-wrapper';
import { ProfileLimited } from '../models/profile-limited';
import { ProfilePersonalData } from '../models/profile-personal-data';
import { ProfileSearchParameters } from '../models/profile-search-parameters';
import { SearchResultWrapper } from '../models/search-result-wrapper';

@Injectable({
    providedIn: 'root'
})
export class ProfileApiService {

    private apiBaseUrl = `${environment.apiUrl}profile`;

    constructor(private httpClient: HttpClient) { }

    public get(profileId: number): Observable<ProfilePersonalData> {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.get<ProfilePersonalData>(`${this.apiBaseUrl}/getProfile`, { params });
    }

    public getLimited(profileId: number): Observable<ProfileLimited> {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.get<ProfileLimited>(`${this.apiBaseUrl}/getLimitedProfile`, { params });
    }

    public sendProfileCreatedMail(profileId: number) : Observable<void> {
        const params = new HttpParams().set('profileId', profileId + '');
        return this.httpClient.post<void>(`${this.apiBaseUrl}/sendProfileCreatedMail`, params );
    }

    public findProfiles(profileSearchParameters: ProfileSearchParameters): Observable<SearchResultWrapper> {
        return this.httpClient.post<SearchResultWrapper>(`${this.apiBaseUrl}/findProfiles`, profileSearchParameters);
    }

    public getAsCsv(profileSearchParameters: ProfileSearchParameters): Observable<HttpResponse<Blob>> {
        return this.httpClient.post<Blob>(`${this.apiBaseUrl}/getAsCsv`, profileSearchParameters, {
            observe: 'response',
            responseType: 'blob' as 'json'
          });
    }

    public getFocusProfiles(baseSearchParameters: BaseSearchParameters): Observable<FocusResultWrapper> {
        return this.httpClient.post<FocusResultWrapper>(`${this.apiBaseUrl}/GetWithFocus`, baseSearchParameters);
    }

    public save(profilePersonalData: FormData): Observable<number> {
        return this.httpClient.post<number>(`${this.apiBaseUrl}`, profilePersonalData);
    }

    public delete(profileId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${profileId}`);
    }
}
