import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserApiService } from '../core/api/user-api.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    public forgotPasswordForm: FormGroup;
    public email = '';
    public showConfirmation = false;


    constructor(private userApiService: UserApiService,
                private toastr: ToastrService,
                private spinner: NgxSpinnerService,
                private formBuilder: FormBuilder,
                private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.buildForm();
    }

    public send() {
        if (this.forgotPasswordForm.invalid) {
            this.toastr.error('Ikke alle felter er udfyldt korrekt!');
            return;
        }

        this.spinner.show();
        this.email = this.forgotPasswordForm.value.email;
        this.userApiService.forgotPassword(this.email).subscribe(_ => {
            this.showConfirmation = true;
            this.spinner.hide();
        });
    }

    public buildForm() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: [this.email, [Validators.required, Validators.email]],
        });
    }

    private get f() { return this.forgotPasswordForm.controls; }
}
