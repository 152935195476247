import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileAdminUpdate } from 'src/app/core/models/profile-admin-update';
import { AdminApiService } from '../../../core/api/admin-api.service';
import { ProfileApiService } from '../../../core/api/profile-api.service';
import { UserRoleEnum } from '../../../core/models/enum/user-role-enum';
import { User } from '../../../core/models/user';
import { AuthenticationService } from '../../../core/services/authentiation.service';
import { ProfileAdminUpdateService } from '../../../core/services/profile-admin-update.service';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

    public showMenu = false;
    public currentUser: User;

    constructor(
        private adminApiService: AdminApiService,
        private profileApiService: ProfileApiService,
        private profileUAdminUpdateService: ProfileAdminUpdateService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    public ngOnInit() {
      this.profileUAdminUpdateService.updated.subscribe((profileAdminUpdate: ProfileAdminUpdate) => {
          if (profileAdminUpdate.userRoleEnum === UserRoleEnum.Admin) {
            if(profileAdminUpdate.id === this.currentUser.adminId) {
              this.adminApiService.get(profileAdminUpdate.id).subscribe(admin => {
                this.authenticationService.updateCurrentUser(admin.name, admin.imagePath);
              });
            }
          } else if (profileAdminUpdate.userRoleEnum === UserRoleEnum.Profile) {
            this.profileApiService.getLimited(profileAdminUpdate.id).subscribe(profileLimited => {
              this.authenticationService.updateCurrentUser(profileLimited.name, profileLimited.imagePath);
            });
          }
      });
    }

    public get isAdmin() {
        return this.currentUser && this.currentUser.userRoleEnumDto === UserRoleEnum.Admin;
    }

    public logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login'], { queryParams: { logout: 'true' }});
    }

    public toggleMenu(show) {
        this.showMenu = show;
    }
}
