import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-profile-image',
    templateUrl: './profile-image.component.html',
    styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
    @Input() public alt: string;
    @Input() public missingImageSrc = '/assets/images/person.png';
    @Input() public showAsBackground = false;
    @Input() public set imagePath(imagePath: string) {
        this.imageUrl = imagePath
            ? environment.apiUrl + 'file/getImage?path=' + imagePath
            : undefined;
    }

    @Input() public set width(width: number) {
        this.widthLocal = width;
    }

    @Input() public set height(height: number) {
        this.heightLocal = height;
    }

    public imageUrl: string;
    public widthLocal?: number;
    public heightLocal?: number;

    constructor() { }

    public ngOnInit() {
    }

    public getUrl(): string {
        if (this.imageUrl) {
            return this.imageUrl +  (this.widthLocal ? `&width=${this.widthLocal}` : '') +
                                    (this.heightLocal ? `&height=${this.heightLocal}` : '');
        }
        return null;
    }

}
