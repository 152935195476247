import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public login(username: string, password: string) {
        const userTmp = new User();
        userTmp.username = username;
        userTmp.password = password;
        return this.http.post<User>(`${environment.apiUrl}user/authenticate`, userTmp)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.saveUserInStorage(user);
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    public updateCurrentUser(name: string, imagePath: string) {
        let user = this.currentUserValue;
        user.name = name;
        user.imagePath = imagePath;
        this.saveUserInStorage(user);
        this.currentUserSubject.next(user);
    }

    public logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    private saveUserInStorage(user: User) {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }
}
