// import { ErrorHandler, Injectable, Injector } from '@angular/core';
// import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { HttpInterceptor, HttpHandler } from '@angular/common/http';
import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentiation.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
// https://scotch.io/bar-talk/error-handling-with-angular-6-tips-and-best-practices192

    constructor(private authenticationService: AuthenticationService,
                private toastr: ToastrService,
                private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
            //    retry(1),
                catchError((error: HttpErrorResponse) => {
                    this.spinner.hide();
                    let errorMessage = '';
                    let reThrowError = true;
                    if (error.status === 401 || error.status === 403) {
                        this.authenticationService.logout();
                        location.reload();
                    } else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error}`;
                    } else {
                        if (error.status === 400) {
                            errorMessage = error.error;
                            reThrowError = false;
                        } else {
                            errorMessage = 'Der er desværre sket en fejl!';
                        }
                    }
                    this.toastr.error(errorMessage);
                    if (reThrowError) {
                        return throwError(errorMessage);
                    }
                    return EMPTY;
                })
            );
    }
}

// @Injectable()
// export class GlobalErrorHandler implements ErrorHandler {

//     constructor(private toastr: ToastrService,
//                 private spinner: NgxSpinnerService) { }

//     handleError(error) {
//         this.spinner.hide();
//         this.toastr.error('Der er sket en fejl!\n[' + error + ']');
//         // // IMPORTANT: Rethrow the error otherwise it gets swallowed
//         console.log('\x1b[31m%s\x1b[0m', error);
//         throw;
//     }

// }
