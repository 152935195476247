import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from '../../../core/services/authentiation.service';
import { first } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { UserRoleEnum } from 'src/app/core/models/enum/user-role-enum';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    @ViewChild('username', { static: false }) username: ElementRef;
    public loginForm: FormGroup;
    public submitted = false;
    public error = '';
    public welcomeText = 'Velkommen tilbage';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    public ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.route.queryParams
        .subscribe(params => {
          console.log(params); 
          if(params.logout === 'true'){
              this.welcomeText = 'Tak for denne gang';
          }          
        }
      );
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    ngAfterViewInit() {
        this.username.nativeElement.focus();
    }

    public onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.toastr.error('Ikke alle felter er udfyldt korrekt');
            return;
        }
        this.spinner.show();

        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                user => {
                    this.router.navigate([this.getRedirect(user)]);
                },
                error => {
                    this.spinner.hide();
                    this.error = error;
                });
    }

    private getRedirect(user: User): string {
        const returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (returnUrl) {
            return returnUrl;
        }

        return user.userRoleEnumDto === UserRoleEnum.Admin ? '/' : '/edit-my-profile-page';
    }
}
