import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailArchivePageComponent } from './pages/email-archive-page/email-archive-page.component';
import { ShowEmailComponent } from './components/show-email/show-email.component';
import { ModalService } from '../core/modal/modal.service';
import { EmailArchiveRoutingModule } from './email-archive-routing.module';



@NgModule({
  declarations: [EmailArchivePageComponent, ShowEmailComponent],
  imports: [
    CommonModule,
    EmailArchiveRoutingModule
  ],
  exports: [
      EmailArchivePageComponent
  ],
  providers: [
    ModalService
],
entryComponents: [
    ShowEmailComponent
]
})
export class EmailArchiveModule { }
