import { Component } from '@angular/core';
import { User } from './core/models/user';
import { Router } from '@angular/router';
import { AuthenticationService } from './core/services/authentiation.service';
import { UserRoleEnum } from './core/models/enum/user-role-enum';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public currentUser: User;
    private title = 'ahoc';

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }
}
