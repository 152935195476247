import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CoreModule } from './core/core.module';
import { BasicAuthInterceptor } from './core/helpers/basic-auth.interceptor';
import { DeactivateGuard } from './core/helpers/deactivate.guard';
import { HttpErrorInterceptor } from './core/helpers/http-error-interceprot';
import { EmailArchiveModule } from './email-archive/email-archive.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginPageComponent } from './login/pages/login-page/login-page.component';
import { MainAdminComponent } from './main-admin/main-admin.component';
import { MainMenuComponent } from './main-menu/components/main-menu/main-menu.component';
import { MainProfileComponent } from './main-profile/main-profile.component';
import { MainComponent } from './main/main.component';
import { ConfirmModalModule } from './shared-ui/confirm-modal/confirm-modal.module';
import { ProfileImageModule } from './shared-ui/profile-image/profile-image.module';


@NgModule({
    declarations: [
        AppComponent,
        LoginPageComponent,
        MainMenuComponent,
        MainComponent,
        MainAdminComponent,
        MainProfileComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        ReactiveFormsModule,
        NgbModule,
        FormsModule,
        ConfirmModalModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({enableHtml: true}),
        NgxSpinnerModule,
        ProfileImageModule,
        EmailArchiveModule, 
    ],
    providers: [
        DeactivateGuard,
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
