import { UserRoleEnum } from './enum/user-role-enum';

export class User {
    public id: number;
    public name: string;
    public username: string;
    public password: string;
    public token?: string;
    public userRoleEnumDto: UserRoleEnum;
    public profileId?: number;
    public adminId?: number;
    public createUserId?: number;
    public imagePath: string;
    public phone: string;
    public email: string;
    public canEdit: boolean;
}
