import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePassword } from '../models/change-password';
import { UserTmp } from '../models/user-tmp';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

    private apiBaseUrl = `${environment.apiUrl}user`;

    constructor(private httpClient: HttpClient) { }

    public forgotPassword(email: string): Observable<Location> {
        let params = new HttpParams();
        params = params.set('email', email);
        return this.httpClient.post<Location>(`${this.apiBaseUrl}/forgotPassword`, params);
    }

    public changePassword(changePassword: ChangePassword): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}/changePassword`, changePassword);
    }

    public createUser(userTmp: UserTmp): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}/createUser`, userTmp);
    }

    public confirmUserCreation(securityToken: string): Observable<void> {
        let params = new HttpParams();
        params = params.set('securityToken', securityToken);
        return this.httpClient.post<void>(`${this.apiBaseUrl}/confirmUserCreation`, params);
    }

    public delete(userCreationId: number) {
        return this.httpClient.delete(`${this.apiBaseUrl}/${userCreationId}`);
    }
}
