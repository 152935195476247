import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Admin } from '../models/admin';
import { AdminSearchParameters } from '../models/admin-search-parameters';
import { AdminSearchResultWrapper } from '../models/admin-search-result-wrapper';


@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

    private apiBaseUrl = `${environment.apiUrl}admin`;

    constructor(private httpClient: HttpClient) { }

    public find(adminSearchParameters: AdminSearchParameters): Observable<AdminSearchResultWrapper> {
        return this.httpClient.post<AdminSearchResultWrapper>(`${this.apiBaseUrl}/find`, adminSearchParameters)
        .pipe(
            catchError(this.handleError)
          );
    }

    public get(id: number): Observable<Admin> {
      const params = new HttpParams().set('id', id + '');
      return this.httpClient.get<Admin>(`${this.apiBaseUrl}/${id}`);
    }  

    public save(admin: FormData): Observable<void> {
        return this.httpClient.post<void>(`${this.apiBaseUrl}`, admin);
    }

    public delete(adminId: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiBaseUrl}/${adminId}`);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
          'Something bad happened; please try again later.');
      }

}
