import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmailLog } from '../models/email-log';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailLogApiService {
    private apiBaseUrl = `${environment.apiUrl}EmailLog`;

    constructor(private httpClient: HttpClient) { }

    public GetEmails(): Observable<EmailLog[]> {
        return this.httpClient.get<EmailLog[]>(`${this.apiBaseUrl}/getEmails`);
    }
}
