import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from '../../shared-ui/confirm-modal/components/confirm-modal.component';
import { ConfirmType } from 'src/app/shared-ui/confirm-modal/models/confirm-type';
import { from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';

@Injectable()
export class ConfirmModalService {

    constructor(private modalService: ModalService) { }

    public confirm(
        titleKey: string,
        messageKey: string,
        messageParams: string | string[] = null,
        titleParams: string | string[] = null,
        dialogSize: 'sm' | 'md' | 'lg' = 'lg',
        type: ConfirmType = ConfirmType.Confirm,
    ): Observable<boolean> {
        const modalRef = this.modalService.open(ConfirmModalComponent, {
            size: dialogSize === 'md' ? undefined : dialogSize,
            centered: true,
        });
        modalRef.componentInstance.title = titleKey;
        modalRef.componentInstance.message = messageKey;

        if (type === ConfirmType.Both) {
            return from(modalRef.result);
        }

        return from(modalRef.result).pipe(filter(isConfirmed => isConfirmed === !!type));
    }
}
