import { Component, OnInit } from '@angular/core';
import { UserApiService } from '../core/api/user-api.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChangePassword } from '../core/models/change-password';
import { IfStmt } from '@angular/compiler';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    public changePassword: ChangePassword;
    public changePasswordForm: FormGroup;
    public passwordConfirm: string;
    public showConfirmation = false;

    constructor(private userApiService: UserApiService,
                private toastr: ToastrService,
                private spinner: NgxSpinnerService,
                private formBuilder: FormBuilder,
                private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.changePassword = new ChangePassword();
        this.changePassword.securityGuid = this.activatedRoute.snapshot.queryParams.token;
        if (!this.changePassword.securityGuid) {
            this.toastr.error('Der er sket en fejl');
        }
        this.buildForm();
    }

    public change() {
        if (this.changePasswordForm.invalid) {
            this.toastr.error('Ikke alle felter er udfyldt korrekt!');
            return;
        }
        if (this.changePasswordForm.value.password !== this.changePasswordForm.value.password) {
            this.toastr.error('De indtastede passwords er ikke ens!');
            return;
        }

        this.changePassword.password = this.changePasswordForm.value.password;
        this.spinner.show();
        this.userApiService.changePassword(this.changePassword).subscribe(_ => {
            this.spinner.hide();
            this.showConfirmation = true;
            this.toastr.success('Password er nu ændret og du kan logge ind med det nye password');
        });
    }

    private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
          let passwordInput = group.controls[passwordKey],
              passwordConfirmationInput = group.controls[passwordConfirmationKey];
          if (passwordInput.value !== passwordConfirmationInput.value) {
            return passwordConfirmationInput.setErrors({notEquivalent: true})
          }
          else {
              return passwordConfirmationInput.setErrors(null);
          }
        }
      }

    private buildForm() {
        this.changePasswordForm = this.formBuilder.group({
            password: [this.changePassword.password, Validators.required],
            passwordConfirm: [this.passwordConfirm, Validators.required]
        },{validator: this.checkIfMatchingPasswords('password', 'passwordConfirm')});
    }

}
