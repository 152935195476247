import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProfileAdminUpdate } from '../models/profile-admin-update';

@Injectable({
  providedIn: 'root'
})
export class ProfileAdminUpdateService {

  updated = new Subject<ProfileAdminUpdate>();

  constructor() { }
}
