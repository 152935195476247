import { NgModule } from '@angular/core';
import { ConfirmModalComponent } from './components/confirm-modal.component';
import { ConfirmModalService } from '../../core/helpers/confirm-modal.service';

@NgModule({
    exports: [ConfirmModalComponent],
    declarations: [ConfirmModalComponent],
    providers: [ConfirmModalService],
    entryComponents: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
